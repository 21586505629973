import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout/layout.js"

const NotFoundPage = () => (
  <Layout>
    <Helmet
      title="404 Not Found"
      meta={[
        { name: "description", content: "The requested page was not found." },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn't exist.</p>
  </Layout>
)

export default NotFoundPage
